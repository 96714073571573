
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { apiRechargeBalance } from '@/api/channel_provider'
@Component({
    components: {}
})
export default class RechargeBalance extends Vue {
    value?: number
    type: number=1
    userId?: number // 渠道商id
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '660px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    $refs!: {
        valueRef: any
    }
    form = {
        channel_provider_id: '', // 渠道商id
        type: 1, // 变动类型：1-渠道商金额；2-可提现金额；3-积分
        action: 1, // 调整类型：0-减少；1-增加
        num: 0, // 调整数量
        remark: '', // 备注
        value: '' // 初始值
    }
    typeName = '' // 变动类型名称

    // 表单验证
    valueRules = {
       
    }
    // 修改后的值
    get lastValue(): number {
        let total = this.value
        if (this.form.action == 1) {
            total = Number(this.form.value) + this.form.num * 1
        } else {
            total = Number(this.form.value) - this.form.num * 1
        }
        return total
    }

   

    @Watch('type', {
        immediate: true
    })
    getType(val: any) {
        if (val == 1) {
            this.typeName = '余额'
            this.$set(this.form, 'type', val)
        } 
    }

    /** S Methods **/
    // 调整渠道商钱包
    updateUserWallet() {
        let num = this.form.num * 1
        if (num <= 0) {
            return this.$message.error('请输入大于0的数字')
        }
        if (!this.form.remark ) {
            return this.$message.error('请填写备注')
        }
        apiRechargeBalance(this.form)
            .then((res: any) => {
                console.log(res)
                // 重新获取渠道商详情
                this.$emit('refresh')
                this.visible = false
            })
            .catch((res: any) => {
                console.log(res)
                this.visible = false
            })
    }


    // 关闭弹窗
    close() {
        this.visible = false

        // 重制表单内容
        this.$set(this.form, 'num', 0)
        this.$set(this.form, 'remark', '')
    }
    open(data:any){
        this.form.value = data.balance
        this.form.channel_provider_id = data.id
        this.visible = true
    }
    /** E Methods **/

    /** S Life Cycle **/
    /** E Life Cycle **/
}
